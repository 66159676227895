<template>
  <div class="platformEquipmentAll">
    <div class="platformEquipmentFirst">
      <h1>华为RH2288V3服务器</h1>
      <div class="platformEquipmentFirst11"></div>
      <div class="platformEquipmentFirst1">
        <div class="platformEquipmentFirst1Left">
          <img src="../../../../assets/img/school/1.jpg" alt="" />
        </div>
        <div class="platformEquipmentFirst1Right">
          <p>
            <strong>尺寸</strong>
            43.5mm*454mm*694mm
          </p>
          <p>
            <strong>重量</strong>
            20kg
          </p>
          <p>
            <strong>电源</strong>
            460W起
          </p>
          <p>
            <strong>工作环境</strong>
            工作温度：5℃~40℃ 工作湿度：8%~90% ，非凝结
          </p>
        </div>
      </div>
    </div>
    <div class="platformEquipmentSecond">
      <div class="platformEquipmentSecond1">
        <div class="platformEquipmentSecond1Left">
          <h2>Dell C6320p C6420四子星2U服务器</h2>
          <p>
            <strong>主板</strong>
            四节点双路2U机箱
          </p>
          <p>
            <strong>内存</strong>
            16个DDR4插槽每节点支持512G最大支持2T
          </p>
          <p>
            <strong>电源</strong>
            支持冗余1400W/1600W电源起
          </p>
          <p>
            <strong>网卡</strong>
            8个1G或可选8个10G 每节点2个1G或每节点2个10G
          </p>
        </div>
        <div class="platformEquipmentSecond1Right">
          <img src="../../../../assets/img/school/2.jpg" alt="" />
        </div>
      </div>
    </div>
    <div class="platformEquipmentThird">
      <div class="platformEquipmentThird1">
        <div class="platformEquipmentThird1Left">
          <img src="../../../../assets/img/school/3.jpg" alt="" />
        </div>
        <div class="platformEquipmentThird1Right">
          <h2>超聚变XFusion 2288H 服务器</h2>
          <p>
            <strong>形态</strong>
            2U----32DIMM
          </p>
          <p>
            <strong>内存</strong>
            32个DDR4内存插槽，灵活可选，最高3200MT/s
          </p>
          <p>
            <strong>温度</strong>
            工作温度：5℃~40℃
          </p>
          <p>
            <strong>RAID支持</strong>
            RAID0/1/10/1E/5/50/6/60等
          </p>
        </div>
      </div>
    </div>
    <div class="platformEquipmentSecond">
      <div class="platformEquipmentSecond1">
        <div class="platformEquipmentSecond1Left">
          <h2>全新H3C华三机架式服务器</h2>
          <div style="margin-bottom:10px"></div>
          <p style="margin-bottom:10px">更高的性能和安全性</p>
          <p style="margin-bottom:10px">
            
            直观可配置的管理系统
          </p>
          <p style="margin-bottom:10px">
           卓越的服务器体验
          </p>
          <p style="margin-bottom:10px">
            更加的低碳环保
          </p>
          <p style="margin-bottom:10px">
            定制化服务
          </p>
        </div>
        <div class="platformEquipmentSecond1Right">
          <img src="../../../../assets/img/school/1.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.platformEquipmentAll {
  width: 100%;
  .platformEquipmentFirst {
    background-color: #f6f6f6;
    padding: 30px;
    h1 {
      text-align: center;
    }
    .platformEquipmentFirst11 {
      border-top: 5px solid #3d4ed0;
      width: 100px;
      margin: 0 auto;
      text-align: center;
    }
    .platformEquipmentFirst1 {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      img {
        width: 400px;
        height: 220px;
      }
      .platformEquipmentFirst1Right {
        font-size: 1.22rem;
        color: rgb(51, 51, 51);
        line-height: 3.5rem;
        margin-left: 1rem;
      }
    }
  }
  .platformEquipmentSecond {
    padding: 30px;
    margin-top: 40px;
    .platformEquipmentSecond1 {
      display: flex;
      justify-content: center;
      align-items: center;
      .platformEquipmentSecond1Left {
        h2 {
          font-weight: 400;
          font-size: 23px;
          color: rgb(51, 51, 51);
        }
        p {
          font-size: 1.02rem;
          color: rgb(51, 51, 51);
          line-height: 1.5rem;
        }
      }
      .platformEquipmentSecond1Right {
        margin-left: 1rem;
        img {
          width: 400px;
          height: 220px;
        }
      }
    }
  }
  .platformEquipmentThird {
    margin-top: 40px;
    background-color: #f6f6f6;
    padding: 30px;
    .platformEquipmentThird1 {
      display: flex;
      justify-content: center;
      align-items: center;
      .platformEquipmentThird1Left {
        img {
          width: 400px;
          height: 220px;
        }
      }
      .platformEquipmentThird1Right {
        margin-left: 1rem;
        h2 {
          font-weight: 400;
          font-size: 23px;
          color: rgb(51, 51, 51);
        }
        p {
          font-size: 1.02rem;
          color: rgb(51, 51, 51);
          line-height: 1.5rem;
        }
      }
    }
  }
}
</style>